html{
  color: $white;
}

/* -- Section colours -- */

.tile{
  color: $white;
}

.bg--darkpurple{
  background-color: $darkpurple;
}

.bg--purple{
  background-color: $purple;
}

.bg--cyan{
  background-color: $cyan;
  color: $darkpurple;

  h2, h1 a{color:$darkpurple}
  .tile h2{color:$white}
}

.bg--grey{
  background-color: $grey;
  color: $darkpurple;
}

.bg--pink{
  background-color: $pink;
  color: $white;
}

.black{
  background-color: $black;
  color: $white;
}

/* -- Tile colours -- */


.bg--darkpurple .tile{
    background-color: $purple;
}

.bg--purple .tile{
    background-color: $darkpurple;
}

.bg--cyan .tile{
    background-color: $purple;
}

.bg--grey .tile{
    background-color: $purple;
}

.tile.pink{
  background-color: $pink;
}

a.tile:hover{
  background-color: $pink;
  color: $white;
}

.bg--darkpurple .tile.current{
  border: 1px solid $purple;
  background: $darkpurple;
}

/* -- Misc colours -- */

.pipe{
  background-color: $cyan;

  &.white{
      background-color: $white;
  }
}

